<template>
  <div
    class="px-3 md:px-0 container max-w-8xl md:pt-10 mx-auto relative"
    v-if="experience"
  >
    <MobileHeader
      :headingText="getHeaderText()"
      routeName="OpenDemosCatalog"
      class="block md:hidden"
    />
    <Heading
      :headingText="getHeaderText()"
      class="hidden md:block"
      routeName="OpenDemosCatalog"
    />
    <div v-if="experience" class="mt-20 md:mt-5 mb-4 md:mb-0">
      <div v-if="experience">
        <div class="evp-wrapper">
          <EVPContainer :evpData="experience.exp_details.details" />
        </div>
      </div>
      <div class="mt-5">
        <ExpFeedback :exp="obkSlugs.evp" />
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import ApiService from "@/common/api.service";
import { apiResource } from "@/common/app.config";
import EVPContainer from "@/components/Demo/EVP/EVPContainer.vue";
import ExpFeedback from "@/components/Demo/ExpFeedback.vue";
import MobileHeader from "@/components/Demo/MobileHeader.vue";
import Heading from "@/components/Demo/Heading.vue";
import obkSlugs from "@/common/slugs";
import Footer from "@/components/Demo/CatalogElements/DesktopFooter.vue";

export default {
  name: "ObkEVP",
  components: { EVPContainer, ExpFeedback, Heading, MobileHeader, Footer },
  props: ["obkid"],
  created() {
    ApiService.get(apiResource.demo.getDetails, {
      slug: obkSlugs.evp,
    })
      .then(({ data }) => {
        this.experience = data.data;
      })
      .catch(() => {});
  },
  data() {
    return {
      experience: null,
      obkSlugs,
    };
  },
  methods: {
    getHeaderText() {
      return this.experience.name;
    },
  },
};
</script>

<style scoped></style>
